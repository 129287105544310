import styled from "styled-components";

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    height: 60px;
    width: 100%;
    background-color: #fedb00;
    /* z-index: 1000; */
    margin-bottom: 0px !important;
    padding: 1rem;
    p {
        color: #777777;
    }
    .footer-logout {
        width:30px;
    }
    .rodape-logout {
        display: flex;
        align-items: center;
        gap: 2px;
    }
    .rodape-logout:hover{
        cursor: pointer;
    }
    .hidden {
    visibility: hidden; /* Ocupará espaço, mas não será visível */
}
`;